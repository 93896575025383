import React from 'react';
import { Link } from 'gatsby';
import { getBlogUrl } from '../../lib/helpers';
import { AspectRatioBox } from '../container/aspect-ratio-box';
import { AssetImg } from '../asset/asset-img';
import styles from './blogpost-teaser.module.scss';

export function BlogPostTeaser({ title, publishedAt, dateSegment, mainImage, slug }) {
  const blogLink = getBlogUrl(dateSegment, slug);
  return (
    <div className={styles.blogTeaser}>
      <Link to={blogLink} className={styles.teaserLink}>
        {mainImage && mainImage.asset && (
          <div className={styles.imageWrapper}>
            <AspectRatioBox width={350} height={230}>
              <AssetImg asset={mainImage.asset} alt={mainImage.alt} className={styles.mainImage} />
            </AspectRatioBox>
          </div>
        )}
        <p className={styles.blogDate}>{publishedAt}</p>
        <h3 className={styles.blogTitle}>{title}</h3>
        <span className={styles.blogLink}>Read more</span>
      </Link>
    </div>
  );
}
