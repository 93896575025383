import React from 'react';
import { Container } from '../container/container';
import styles from './page-title.module.scss';

export function PageTitle({ title }) {
  return (
    <Container variant="thin" className={styles.pageTitle}>
      <h1>{title}</h1>
    </Container>
  );
}
