import React from 'react';
import { Link } from 'gatsby';
import { Container } from '../container/container';
import { getBlogUrl } from '../../lib/helpers';
import BlockText from '../block-content/block-text';
import { AssetImg } from '../asset/asset-img';
import styles from './featured-blogpost.module.scss';

export function FeaturedBlogPost({ post = {} }) {
  const { title, excerpt, dateSegment, mainImage = {}, slug } = post;
  const blogLink = getBlogUrl(dateSegment, slug);

  return (
    <Container className={styles.container}>
      {mainImage.asset && <AssetImg asset={mainImage.asset} className={styles.image} alt={mainImage.alt} />}
      <div className={styles.textBoxWrapper}>
        <Link to={blogLink} className={styles.teaserLink}>
          <div className={styles.textBoxContent}>
            <h5 className={styles.blogSubTitle}>Featured article</h5>
            <h3 className={styles.blogTitle}>{title}</h3>
            <BlockText blocks={excerpt} />
            <span className={styles.blogLink}>Read more</span>
          </div>
        </Link>
      </div>
    </Container>
  );
}
