import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '../components/container/container';
import { LayoutContent } from '../components/layout/layout-content';
import { PageTitle } from '../components/page-title/page-title';
import { BlogPostTeaserGrid } from '../components/blogpost/blogpost-teaser-grid';
import { FeaturedBlogPost } from '../components/blogpost/featured-blogpost';
import { SEO } from '../components/seo';

const BlogPage = () => {
  const {
    page,
    posts: { edges: postNodes },
  } = useStaticQuery(graphql`
    {
      page: sanityBlogOverviewPage(_id: { eq: "blogOverview" }) {
        id: _id
        slug {
          current
        }
        featuredArticle {
          id: _id
          title
          categories {
            title
            language
            id: _id
          }
          slug {
            current
          }
          excerpt: _rawExcerpt
          publishedAt: publishedAt(formatString: "MMM D, YYYY")
          dateSegment: publishedAt(formatString: "YYYY/MM")
          mainImage {
            asset {
              id
              localFile {
                publicURL
                childImageSharp {
                  fluid(
                    maxWidth: 1780
                    srcSetBreakpoints: [340, 768, 1780]
                    traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#08080d", color: "#c62d34" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
            alt
          }
          body: _rawBody
        }
        description
        keywords
        title
      }
      posts: allSanityPost(
        filter: {
          slug: { current: { ne: null } }
          publishedAt: { ne: null }
          categories: { elemMatch: { language: { eq: "en" } } }
        }
        sort: { order: DESC, fields: publishedAt }
      ) {
        edges {
          node {
            id: _id
            title
            categories {
              language
              title
              id: _id
            }
            slug {
              current
            }
            publishedAt: publishedAt(formatString: "MMM D, YYYY")
            dateSegment: publishedAt(formatString: "YYYY/MM")
            mainImage {
              asset {
                id
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(
                      maxWidth: 480
                      maxHeight: 316
                      srcSetBreakpoints: [200, 480, 768]
                      traceSVG: { color: "#c62d34" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `);

  const { description, keywords, title, featuredArticle } = page;

  return (
    <LayoutContent>
      <SEO title={title} description={description} keywords={keywords} />
      <PageTitle title={title} />
      <FeaturedBlogPost post={featuredArticle} />
      <Container variant="thin">
        <div>
          <h2>Read More Articles</h2>
          <p>{description}</p>
        </div>
        <BlogPostTeaserGrid
          postNodes={postNodes.map((edge) => edge.node).filter((post) => post.id !== featuredArticle.id)}
        />
      </Container>
    </LayoutContent>
  );
};

export default BlogPage;
