import React from 'react';
import { BlogPostTeaser } from './blogpost-teaser';
import styles from './blogpost-teaser.module.scss';

/**
 * @return {null} or a grind of post teaser
 */
export function BlogPostTeaserGrid({ postNodes }) {
  if (!postNodes) {
    return null;
  }

  return (
    <div className={styles.blogTeaserList}>
      {postNodes.map(({ mainImage, title, publishedAt, dateSegment, slug: { current: slug } }, idx) => (
        <BlogPostTeaser
          key={`post-preview-${idx}`}
          mainImage={mainImage}
          title={title}
          dateSegment={dateSegment}
          publishedAt={publishedAt}
          slug={slug}
        />
      ))}
    </div>
  );
}
